import { FC } from 'react';
import { IItemCharacteristic } from 'components/card-rates/interfaces/interfaces';
import * as S from 'components/card-rates/styles/styledItemCharacteristic';
import Select from 'assets/images/ui-elements/rates/select-green.svg';

const ItemCharacteristic: FC<IItemCharacteristic> = ({
  title,
  description,
}) => {
  return (
    <S.ItemCharacteristicContainer>
      <Select />
      <S.ItemCharacteristicContent>
        <S.ItemCharacteristicTitle>{title}</S.ItemCharacteristicTitle>
        <S.ItemCharacteristicDescription>
          {description}
        </S.ItemCharacteristicDescription>
      </S.ItemCharacteristicContent>
    </S.ItemCharacteristicContainer>
  );
};

export default ItemCharacteristic;
