import styled from 'styled-components';
import { COLORS } from 'globalStyles/colors';

const ItemCharacteristicContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemCharacteristicContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const ItemCharacteristicTitle = styled.p`
  font: 800 1.6rem 'Avenir', sans-serif;
  font-style: normal;
  line-height: 2.2rem;
  color: ${COLORS.primaryGrey};
  padding-bottom: 0.3rem;
`;

const ItemCharacteristicDescription = styled.p`
  font: 700 3rem 'Avenir', sans-serif;
  font-style: normal;
  line-height: 3.3rem;
`;

export {
  ItemCharacteristicContent,
  ItemCharacteristicContainer,
  ItemCharacteristicTitle,
  ItemCharacteristicDescription,
};
