import Section from 'components/section/components/Section';
import useHowItWorks from './useHowItWorks';
import CardHowItWorks from 'components/card-how-it-works/components/CardHowItWorks';
import { iconsType, numbersType, WORKS } from '../../constants/works';
import * as Markup from 'globalStyles/markup';
import { useTranslation } from 'react-i18next';
import ButtonHyperLink from '../../components/buttons/components/ButtonHyperLink';
import { HYPER_LINKS } from '../../constants/hyperLinks';

const HowItWorks = () => {
  const { t } = useTranslation();

  const { title, works, buttonName } = useHowItWorks();

  return (
    <Section id={HYPER_LINKS.ids.howItWorks} title={t(title)}>
      <Markup.CardRow>
        {works?.slice(0, 3)?.map(({ id, title, iconNumber, icon }) => (
          <CardHowItWorks
            key={id}
            title={t(title)}
            iconNumber={WORKS.numbers[iconNumber as numbersType]}
            // @ts-ignore
            icon={WORKS.icons[icon as iconsType]}
          />
        ))}
      </Markup.CardRow>
      <Markup.CardRow2>
        {works?.slice(3)?.map(({ id, title, iconNumber, icon }) => (
          <CardHowItWorks
            key={id}
            title={t(title)}
            iconNumber={WORKS.numbers[iconNumber as numbersType]}
            // @ts-ignore
            icon={WORKS.icons[icon as iconsType]}
          />
        ))}
      </Markup.CardRow2>

      <ButtonHyperLink title={t(buttonName)} to={HYPER_LINKS.links.rates} />
    </Section>
  );
};

export default HowItWorks;
