import { FC } from 'react';
import { IButtonHyperLink } from 'components/buttons/interfaces/interface';
import { Button } from '../index';
import * as Markup from '../../../globalStyles/markup';

const BUTTON_SIZES = {
  width: 190,
  height: 60,
};

const ButtonHyperLink: FC<IButtonHyperLink> = ({ title, to }) => {
  return (
    <Markup.ButtonContainer>
      <Button
        width={BUTTON_SIZES.width}
        height={BUTTON_SIZES.height}
        to={to}
        text={title}
        isGreyBorder={true}
        isBlack={true}
      />
    </Markup.ButtonContainer>
  );
};

export default ButtonHyperLink;
