import useFaqs from './useFaqs';
import Section from 'components/section/components/Section';
import CardFaq from 'components/card-faq/components/CardFaq';
import { COLORS } from 'globalStyles/colors';
import { useTranslation } from 'react-i18next';
import ButtonHyperLink from '../../components/buttons/components/ButtonHyperLink';
import { HYPER_LINKS } from '../../constants/hyperLinks';

const styles = {
  display: 'flex',
  flexDirection: 'column',
};

const Faqs = () => {
  const { title, FAQs, support, buttonName } = useFaqs();
  const { t } = useTranslation();

  return (
    <Section id="faqs" title={t(title)} styles={styles}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {FAQs?.map(({ id, title, description }) => (
          <CardFaq key={id} title={t(title)} description={t(description)} />
        ))}
      </div>
      {/* todo: add styled components */}
      <div
        style={{
          font: '400 2rem Avenir, sans-serif',
          marginTop: '4.8rem',
        }}
      >
        <p style={{ color: COLORS.primaryGrey, marginBottom: '1.6rem' }}>
          {t(support.message)}
        </p>
        <p style={{ color: COLORS.white }}>{support.gmail}</p>
      </div>

      <ButtonHyperLink
        title={t(buttonName)}
        to={HYPER_LINKS.links.getInTouch}
      />
    </Section>
  );
};

export default Faqs;
