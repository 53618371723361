import Section from 'components/section/components/Section';
import useRates from './useRates';
import CardRates from '../../components/card-rates/components/CardRates';
import * as Markup from 'globalStyles/markup';
import { useTranslation } from 'react-i18next';
import ButtonHyperLink from '../../components/buttons/components/ButtonHyperLink';
import { HYPER_LINKS } from '../../constants/hyperLinks';

const Rates = () => {
  const { t } = useTranslation();

  const { title, rates, buttonName } = useRates();

  return (
    <Section id={HYPER_LINKS.ids.rates} title={t(title)}>
      <Markup.CardRowRates>
        {rates?.map(({ title, characteristic, image }, index) => (
          <CardRates
            key={index}
            title={t(title)}
            characteristic={characteristic}
            image={image}
          />
        ))}
      </Markup.CardRowRates>

      <ButtonHyperLink title={t(buttonName)} to={HYPER_LINKS.links.fleet} />
    </Section>
  );
};

export default Rates;
