import styled from 'styled-components';
import { COLORS } from 'globalStyles/colors';
import {
  MOBILE_375,
  MOBILE_425,
  TABLET_1024,
} from '../../../constants/breakpoints';

const CardHowItWorksContainer = styled.div`
  width: 30rem;
  justify-content: center;
  margin-bottom: 2rem;
  @media screen and (max-width: ${TABLET_1024}) {
    flex: 0 0 50%;
    height: 30rem;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    flex: 0 0 100%;
    height: 37rem;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    flex: 0 0 100%;
    height: 37rem;
  }
`;

const CardHowItWorksNumber = styled.div`
  display: flex;
  justify-content: center;
`;

const CardHowItWorksTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.6rem;
  padding-bottom: 1.3rem;
`;

const CardHowItWorksTitle = styled.p`
  font: 2.4rem 'Avenir Heavy', sans-serif;
  user-select: none;
  line-height: 3.3rem;
  color: ${COLORS.white};
  @media screen and (max-width: ${MOBILE_375}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
  }
`;

const CardHowItWorksImage = styled.div`
  display: flex;
  justify-content: center;
`;

export {
  CardHowItWorksContainer,
  CardHowItWorksNumber,
  CardHowItWorksTitleWrapper,
  CardHowItWorksTitle,
  CardHowItWorksImage,
};
