import FreeParking from 'assets/images/ui-elements/cards/free-parking.svg';
import GreenEnergy from 'assets/images/ui-elements/cards/green-energy.svg';
import WideServiceCoverage from 'assets/images/ui-elements/cards/wide-service-coverage.svg';
import Insurance from 'assets/images/ui-elements/cards/insurance.svg';
import FreeCharging from 'assets/images/ui-elements/cards/free-charging.svg';
import AmazingFeatures from 'assets/images/ui-elements/cards/amazing-features.svg';
import AffordableTariffs from 'assets/images/ui-elements/cards/affordable-tariffs.svg';

export type advantagiousType =
  | 'green-energy'
  | 'free-parking'
  | 'wide-service-coverage'
  | 'free-charging'
  | 'amazing-features'
  | 'affordable-tariffs'
  | 'insurance';

export const ADVANTAGIOUS = {
  'green-energy': GreenEnergy,
  'free-parking': FreeParking,
  'wide-service-coverage': WideServiceCoverage,
  'free-charging': FreeCharging,
  'amazing-features': AmazingFeatures,
  'affordable-tariffs': AffordableTariffs,
  insurance: Insurance,
};
