import 'globalStyles/normalize.scss';
import * as Markup from 'globalStyles/markup';
import Advantagious from 'templates/advantagious/Advantagious';
import HowItWorks from 'templates/how-it-works/HowItWorks';
import Rates from 'templates/rates/Rates';
import Footer from 'templates/footer/Footer';
import GetInTouch from 'templates/get-in-touch/GetInTouch';
import SliderPage from 'components/slider/components/SliderPage';
import Faqs from 'templates/faqs/Faqs';
import Head from 'templates/head/Head';
import Header from 'components/header/components/Header';
import { useAppNavigation } from '../hooks/useAppNavigation';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import SEO from 'components/seo/SEO';
// import Featured from 'templates/featured/Featured';

export default function App() {
  const location = useLocation();
  useAppNavigation({ location });

  return (
    <Markup.PageWrapper>
      <SEO />
      <Markup.GlobalStyle />
      <Markup.MainContainer>
        <Header />
        <Head type="home" />
        <Markup.Main>
          {/*<Featured />*/}
          <Advantagious />
          <HowItWorks />
          <Rates />
          <SliderPage />
          <Faqs />
          <GetInTouch />
          <Footer />
        </Markup.Main>
      </Markup.MainContainer>
    </Markup.PageWrapper>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
