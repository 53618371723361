import { graphql, useStaticQuery } from 'gatsby';

const useSlider = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, cars, buttonName },
      },
    },
  } = useStaticQuery<any>(graphql`
    query fleet {
      markdownRemark(frontmatter: { type: { eq: "fleet" } }) {
        frontmatter {
          english {
            title
            cars {
              id
              name
              description
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            buttonName
          }
        }
      }
    }
  `);

  return {
    title,
    cars,
    buttonName,
  };
};

export default useSlider;
