import styled from 'styled-components';
import { COLORS } from 'globalStyles/colors';
import { MOBILE_425 } from '../../../constants/breakpoints';

const CardContainer = styled.div`
  background-color: ${COLORS.tertiaryGrey};
  max-height: 414px;
  max-width: 570px;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4.8rem 4.8rem 3.2rem;
  flex: 0 0 49%;
  @media (max-width: 500px) {
    // todo: ??
    padding: 2.4rem 2.6rem 3.2rem;
  }
  @media (max-width: ${MOBILE_425}) {
    flex: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font: 700 4.8rem 'Rajdhani', sans-serif;
  font-style: normal;
  line-height: 33px;
  color: ${COLORS.black};
  display: flex;
  align-items: center;
`;

const ComingSoon = styled.div`
  font: 700 2.4rem 'Avenir', sans-serif;
  font-style: normal;
  color: ${COLORS.black};
  margin-left: 20px;
  margin-right: 20px;
`;

const Characteristics = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 32px;

  > div:nth-child(2) {
    margin-left: 32px;
  }

  @media (max-width: 500px) {
    // todo: ??
    margin-top: 10px;
  }
`;

const ImageCont = styled.div`
  max-width: 453px;
`;

const Image = styled.img`
  object-fit: contain;
  max-width: none;
  width: 100%;
`;

export {
  CardContainer,
  Wrapper,
  Title,
  ComingSoon,
  Characteristics,
  ImageCont,
  Image,
};
