import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICardSliderProps } from 'components/card-for-slider/interfaces/interface';
import * as S from 'components/card-for-slider/styles/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CardSlider: FC<ICardSliderProps> = ({
  title,
  description,
  image,
  isComingSoon = false,
}) => {
  const { t } = useTranslation();

  const img = getImage(image);

  return (
    <S.CardContainer>
      <S.Wrapper>
        <S.Title>
          {title}
          {isComingSoon && <S.ComingSoon>{t(description)}</S.ComingSoon>}
        </S.Title>
        <S.Characteristics />
        <S.ImageCont>
          <GatsbyImage alt="car" image={img} />
        </S.ImageCont>
      </S.Wrapper>
    </S.CardContainer>
  );
};

export default CardSlider;
