import { FC } from 'react';
import CardSlider from 'components/card-for-slider/components/CardSlider';
import Section from 'components/section/components/Section';
import * as S from 'components/slider/styles/styled';
import { useTranslation } from 'react-i18next';
import useSlider from 'templates/slider/useSlider';
import ButtonHyperLink from '../../buttons/components/ButtonHyperLink';
import { HYPER_LINKS } from '../../../constants/hyperLinks';

const SliderPage: FC = () => {
  const { t } = useTranslation();
  const { title, cars, buttonName } = useSlider();

  return (
    <Section id={HYPER_LINKS.ids.fleet} title={t(title)}>
      <S.Cards>
        {cars?.map((car: any) => {
          // todo: interface
          return (
            <CardSlider
              key={car?.id}
              title={car?.name}
              description={car?.description}
              isComingSoon={car?.description}
              image={car?.image}
            />
          );
        })}
      </S.Cards>
      <ButtonHyperLink title={t(buttonName)} to="#faqs" />
    </Section>
  );
};

export default SliderPage;
