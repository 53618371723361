import { graphql, useStaticQuery } from 'gatsby';
import { IAdvantagiousResponse } from 'interfaces/interfaces';

const useAdvantagious = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, advantages, buttonName },
      },
    },
  } = useStaticQuery<IAdvantagiousResponse>(graphql`
    query advantages {
      markdownRemark(frontmatter: { type: { eq: "advantages" } }) {
        frontmatter {
          english {
            title
            advantages {
              id
              icon
              title
              description
            }
            buttonName
          }
        }
      }
    }
  `);

  return { title, advantages, buttonName };
};

export default useAdvantagious;
