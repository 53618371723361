import useAdvantagious from './useAdvantagious';
import Section from 'components/section/components/Section';
import CardInfo from 'components/card-info/components/CardInfo';
import { ADVANTAGIOUS, advantagiousType } from '../../constants/advantagious';
import { useTranslation } from 'react-i18next';
import ButtonHyperLink from 'components/buttons/components/ButtonHyperLink';
import { HYPER_LINKS } from '../../constants/hyperLinks';

const Advantagious = () => {
  const { t } = useTranslation();

  const { title, advantages, buttonName } = useAdvantagious();

  return (
    <Section id={HYPER_LINKS.ids.ourAdvantages} title={t(title)}>
      {advantages?.map(({ id, title, icon, description, link }) => (
        <CardInfo
          key={id}
          title={t(title)}
          description={t(description)}
          icon={ADVANTAGIOUS[icon as advantagiousType]}
          withOpen={!link}
          isLink={!!link}
          link={link}
        />
      ))}
      <ButtonHyperLink
        title={t(buttonName)}
        to={HYPER_LINKS.links.howItWorks}
      />
    </Section>
  );
};

export default Advantagious;
