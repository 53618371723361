import { FC, useState } from 'react';
import { ICardFaq } from '../interfaces/interfaces';
import * as S from '../styles/styled';

const DEFAULT_HEIGHT_CARD_FAQ = 62;

const CardFaq: FC<ICardFaq> = ({
  title,
  description,
  withOpen = true,
  height = DEFAULT_HEIGHT_CARD_FAQ,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <S.CardFaqContainer onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
      <S.CardFaqHeader>
        <S.CardFaqTitleContainer>
          <S.CardFaqTitle>{title}</S.CardFaqTitle>
        </S.CardFaqTitleContainer>

        {withOpen && (
          <S.CardFaqArrowOpen
            onClick={() => setOpen(!isOpen)}
            $isOpen={isOpen}
          />
        )}
      </S.CardFaqHeader>

      <S.CardFaqDescription isOpen={isOpen} height={height}>
        {description}
      </S.CardFaqDescription>
    </S.CardFaqContainer>
  );
};

export default CardFaq;
