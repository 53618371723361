import { graphql, useStaticQuery } from 'gatsby';
import { IFaqsResponse } from 'interfaces/interfaces';

const useFaqs = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, FAQs, support, buttonName },
      },
    },
  } = useStaticQuery<IFaqsResponse>(graphql`
    query faqs {
      markdownRemark(frontmatter: { type: { eq: "faqs" } }) {
        frontmatter {
          english {
            title
            FAQs {
              id
              title
              description
            }
            support {
              message
              gmail
            }
            buttonName
          }
        }
      }
    }
  `);

  return { title, FAQs, support, buttonName };
};

export default useFaqs;
