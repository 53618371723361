import { COLORS } from 'globalStyles/colors';
import styled from 'styled-components';
import Slider from 'react-slick';
import { MOBILE_425 } from '../../../constants/breakpoints';

const ButtonsGroup = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 858px) {
    // todo: ??
    margin-bottom: 48px;
  }
`;

const City = styled.div`
  margin-right: 48px;
`;

const Cars = styled.div`
  @media (max-width: 858px) {
    // todo: ??
    margin-top: 32px;
  }
`;

const Title = styled.div`
  font: 800 1.6rem 'Avenir', sans-serif;
  line-height: 22px;
  color: ${COLORS.primaryGrey};
`;

const Buttons = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 24px;

  > div {
    margin-right: 16px;
  }
`;

const SliderContainer = styled(Slider)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
`;

const Cards = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  > div:nth-child(1) {
    //margin-right: 4.8rem;
  }

  @media (max-width: 1165px) {
    // todo: ??
    flex-direction: column;

    > div:nth-child(2) {
      margin-top: 4rem;
    }

    > div:nth-child(1) {
      margin-right: 0;
    }
  }
  @media (max-width: ${MOBILE_425}) {
    width: 100%;
  }
`;

const ArrowContainer = styled.div<{ isNext?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  height: 100%;
  width: 54px;
  position: absolute;
  background-color: ${COLORS.tertiaryGrey};
  z-index: 20;
  top: 0;
  ${({ isNext }) => (isNext ? 'right: 0' : 'left: 0')};
  ${({ isNext }) =>
    isNext
      ? 'box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25)'
      : 'box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25)'};
  ${({ isNext }) =>
    isNext
      ? 'border-radius: 16px 0px 0px 16px'
      : 'border-radius: 0px 16px 16px 0px'};

  > svg {
    position: absolute;
    top: 50%;
    ${({ isNext }) => (isNext ? 'right: 30%' : 'left: 30%')};
    ${({ isNext }) => !isNext && 'transform: rotate(180deg)'};
  }
`;

const ArrowImage = styled.img``;

export {
  ButtonsGroup,
  City,
  Cars,
  Title,
  Buttons,
  SliderContainer,
  Cards,
  ArrowContainer,
  ArrowImage,
};
