import { graphql, useStaticQuery } from 'gatsby';
import { IRatesResponse } from 'interfaces/interfaces';

const useRates = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, rates, buttonName },
      },
    },
  } = useStaticQuery<IRatesResponse>(graphql`
    query rates {
      markdownRemark(frontmatter: { type: { eq: "rates" } }) {
        frontmatter {
          english {
            title
            rates {
              title
              image
              characteristic {
                title
                description
              }
            }
            buttonName
          }
        }
      }
    }
  `);

  return { title, rates, buttonName };
};

export default useRates;
