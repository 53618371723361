import styled from 'styled-components';
import { COLORS } from 'globalStyles/colors';
import {
  DESKTOP,
  MOBILE_375,
  MOBILE_425,
  TABLET_1024,
} from '../../../constants/breakpoints';

const CardRatesContainer = styled.div`
  width: 611px;
  height: 332px;
  background-color: ${COLORS.tertiaryGrey};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.6rem;
  padding: 4.8rem;
  margin-bottom: 4rem;
  @media screen and (max-width: ${DESKTOP}) {
    flex: 0 0 49%;
  }
  @media screen and (max-width: ${TABLET_1024}) {
    flex: none;
    margin-right: 0;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    width: 100%;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    margin-right: 0;
  }
`;

const CardRatesTitle = styled.p`
  font: 700 4.3rem 'Rajdhani', sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
  color: ${COLORS.black};
`;

const CardRatesCharacteristic = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const CardRatesCharacteristicListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 2.2rem;
`;

const ImageCar = styled.img`
  margin-top: 4rem;
  width: 100%;
  height: 17.3rem;
`;

export {
  CardRatesContainer,
  CardRatesTitle,
  CardRatesCharacteristic,
  ImageCar,
  CardRatesCharacteristicListContainer,
};
