import { FC } from 'react';
import * as S from 'components/card-how-it-works/styles/styled';
import { ICardHowItWorks } from 'components/card-how-it-works/interfaces/interfaces';

const CardHowItWorks: FC<ICardHowItWorks> = ({
  title,
  icon: Icon,
  iconNumber: IconNumber,
}) => {
  return (
    <S.CardHowItWorksContainer>
      <S.CardHowItWorksNumber>
        <IconNumber />
      </S.CardHowItWorksNumber>
      <S.CardHowItWorksTitleWrapper>
        <S.CardHowItWorksTitle>{title}</S.CardHowItWorksTitle>
      </S.CardHowItWorksTitleWrapper>
      <S.CardHowItWorksImage>
        <Icon />
      </S.CardHowItWorksImage>
    </S.CardHowItWorksContainer>
  );
};

export default CardHowItWorks;
