import { graphql, useStaticQuery } from 'gatsby';
import { IHowItWorksResponse } from 'interfaces/interfaces';

const useHowItWorks = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, works, buttonName },
      },
    },
  } = useStaticQuery<IHowItWorksResponse>(graphql`
    query works {
      markdownRemark(frontmatter: { type: { eq: "works" } }) {
        frontmatter {
          english {
            title
            works {
              id
              iconNumber
              title
              icon
            }
            buttonName
          }
        }
      }
    }
  `);

  return { title, works, buttonName };
};

export default useHowItWorks;
