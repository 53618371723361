import styled from 'styled-components';
import { IOpenCard } from 'components/card-faq/interfaces/interfaces';
import ArrowCard from 'assets/images/ui-elements/arrows/arrow-card.svg';
import { COLORS } from 'globalStyles/colors';
import {
  MOBILE_375,
  MOBILE_425,
  TABLET_1024,
} from '../../../constants/breakpoints';

const CardFaqContainer = styled.div<Partial<IOpenCard>>`
  position: relative;
  height: 100%;
  width: 40rem;
  border: 2px solid ${COLORS.tertiaryGrey};
  border-radius: 0.8rem;
  padding: 8px 16px;
  box-shadow: 0 0.4rem 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: all 0.3s linear;
  flex: 0 0 calc(33.333% - 3rem);
  margin-bottom: 2rem;
  justify-content: space-between;
  cursor: pointer;
  @media screen and (max-width: ${TABLET_1024}) {
    flex: 0 0 49%;
  }
  @media screen and (max-width: ${MOBILE_425}) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    flex: 0 0 100%;
  }
`;

const CardFaqHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardFaqTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`;

const CardFaqArrowOpen = styled(ArrowCard)<Partial<IOpenCard>>`
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};
  cursor: pointer;
  transition: all 0.3s linear;
  margin-top: 0.8rem;
  @media screen and (max-width: ${MOBILE_425}) {
    top: 2rem;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    top: 2.3rem;
  }
`;

const CardFaqTitle = styled.p`
  font: 400 20px 'Avenir', sans-serif;
  user-select: none;
  color: ${COLORS.white};
  @media screen and (max-width: ${MOBILE_425}) {
    line-height: 4.5rem;
  }
  @media screen and (max-width: ${MOBILE_375}) {
    line-height: 4.5rem;
  }
`;

const CardFaqDescription = styled.p<Partial<IOpenCard>>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding-top: 16px;
  font: normal 2rem 'Avenir', sans-serif;
  line-height: 2.7rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
  color: ${COLORS.primaryGrey};
`;

export {
  CardFaqContainer,
  CardFaqHeader,
  CardFaqTitleContainer,
  CardFaqTitle,
  CardFaqDescription,
  CardFaqArrowOpen,
};
