import { FC } from 'react';
import { ICardRates } from 'components/card-rates/interfaces/interfaces';
import * as S from 'components/card-rates/styles/styled';
import ItemCharacteristic from 'components/card-rates/components/ItemCharacteristic';
import Day from 'assets/images/ui-elements/rates/day.svg';
import Week from 'assets/images/ui-elements/rates/week.svg';
import Month from 'assets/images/ui-elements/rates/month.svg';
import { useTranslation } from 'react-i18next';

const CardRates: FC<ICardRates> = ({ title, characteristic, image }) => {
  const { t } = useTranslation();

  return (
    <S.CardRatesContainer>
      <S.CardRatesTitle>{t(title)}</S.CardRatesTitle>
      <S.CardRatesCharacteristic>
        {image === 'day' ? <Day /> : image === 'week' ? <Week /> : <Month />}
        <S.CardRatesCharacteristicListContainer>
          {characteristic.map(({ title, description }, index) => (
            <ItemCharacteristic
              key={index}
              title={t(title)}
              description={t(description)}
            />
          ))}
        </S.CardRatesCharacteristicListContainer>
      </S.CardRatesCharacteristic>
    </S.CardRatesContainer>
  );
};

export default CardRates;
